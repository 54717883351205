import { combineReducers } from 'redux';

import results, { initialState as resultsInitialState } from '@/ducks/results/resultsReducer';
import object, { initialState as objectInitialState } from '@/ducks/object/objectReducer';
import profile, { initialState as profileInitialState } from '@/ducks/profile/profileReducer';
import order, { initialState as orderInitialState } from '@/ducks/order/orderReducer';
import booking, { initialState as bookingInitialState } from '@/ducks/booking/bookingReducer';
import price, { initialState as priceInitialState } from '@/ducks/price/priceReducer';

export const initialState = {
    results: resultsInitialState,
    objectData: objectInitialState,
    profileData: profileInitialState,
    orderData: orderInitialState,
    bookingData: bookingInitialState,
    priceData: priceInitialState,
};

export const reducer = (state, action) =>
    combineReducers({
        bookingData: booking,
        objectData: object,
        profileData: profile,
        orderData: order,
        priceData: price,
        results,
        loginId: (init = {}) => init,
        deviceType: (init = {}) => init,
    })(state, action);
