/*
 * IMPORTANT: We use /fp-versions of date-fns, so all arguments are in the opposite order to the standard docs.
 * See: https://date-fns.org/v2.22.1/docs/FP-Guide
 */

import { nb } from 'date-fns/locale';
import {
    addDays,
    addMonths,
    differenceInCalendarDays,
    differenceInYears,
    endOfDay,
    formatISOWithOptions,
    formatWithOptions,
    isAfter,
    isBefore,
    isValid,
    isWithinInterval,
    parse,
    parseISO,
    setDate,
    startOfDay,
    subDays,
    toDate,
} from 'date-fns/fp';

export {
    addSeconds,
    addMinutes,
    addHours,
    addDays,
    differenceInCalendarDays,
    differenceInMinutes,
    endOfDay,
    getDay,
    isAfter,
    isBefore,
    isSameDay,
    isValid,
    isWithinInterval,
    max,
    nextDay,
    parseISO,
    startOfDay,
    subDays,
} from 'date-fns/fp';

// Type check
export const isDate = (obj: unknown): boolean => obj instanceof Date || typeof obj === 'number';

// Relative dates
export const isPast: (date: Date | number) => boolean = isBefore(startOfDay(Date.now()));
export const isFuture: (date: Date | number) => boolean = isAfter(endOfDay(subDays(1, Date.now())));
export const isFutureRange = (to: Date | number, from: Date | number): boolean =>
    isDate(from) && isDate(to) && isFuture(from) && isAfter(from, to);

// Intervals
export const isWithinOneYearOfToday: (date: Date | number) => boolean = isWithinInterval({
    start: startOfDay(Date.now()),
    end: addDays(366, startOfDay(Date.now())), // leap years are cool? ¯\_(ツ)_/¯
});

export const isYoungerThan18 = (birthDate: Date): boolean => differenceInYears(birthDate, Date.now()) < 18;

export const isNotWithinOneYearOfToday = (date: Date | number): boolean => !isWithinOneYearOfToday(date);

// Diff
export const travelDaysBetween = (start: Date | number, end: Date | number): number => differenceInCalendarDays(start, end) + 1;
// biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
export const safeDifferenceInCalendarDays = (other: any, date: any): number | undefined =>
    isDate(other) && isDate(date) ? differenceInCalendarDays(other, date) : undefined;

// Formatting
export const formatISODate: (date: Date | number) => string = formatISOWithOptions({ representation: 'date' }); // 2023-05-23
export const formatISODateTime: (date: Date | number) => string = formatWithOptions({}, "yyyy-MM-dd'T'HH:mm"); // 2023-05-23T15:00
export const formatClientDate: (date: Date | number) => string = formatWithOptions({}, 'dd.MM.yyyy'); // 23.05.2023

export const formatReadableDate: (date: Date | number) => string = formatWithOptions({ locale: nb }, "eeeeee'.' dd MMM"); // ti. 16 nov
export const formatReadableDateWithYear: (date: Date | number) => string = formatWithOptions({ locale: nb }, "eeeeee'.' dd MMM yyyy"); // ti. 16 nov 2024
export const formatReadableDateShort: (date: Date | number) => string = formatWithOptions({ locale: nb }, "eeeeee'.' dd.MM"); // ti. 16.11
export const formatReadableDateShortNoWeekday: (date: Date | number) => string = formatWithOptions({ locale: nb }, 'dd. MMM'); // 16. nov
export const formatReadableDateShortWithYear: (date: Date | number) => string = formatWithOptions({ locale: nb }, 'dd. MMM yyyy'); // 16. nov 2021
export const formatReadableDateFull: (date: Date | number) => string = formatWithOptions({ locale: nb }, "eeee d'.' MMMM yyyy"); // tirsdag 16. november 2021
export const formatReadableDayTime: (date: Date | number) => string = formatWithOptions({ locale: nb }, "eeeeee'.' HH:mm"); // ti. 15:00
export const formatReadableTime: (date: Date | number) => string = formatWithOptions({ locale: nb }, 'HH:mm'); // 15:00

// Safe formatting (date-fns throws 'Invalid Date' error when passed weird data)
// biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
export const safeFormatClientDate = <T = string>(date: any, otherwise: string | T = ''): string | T =>
    isDate(date) ? formatClientDate(date) : otherwise;
// biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
export const safeFormatISODate = <T = string>(date: any, otherwise: string | T = ''): string | T =>
    isDate(date) ? formatISODate(date) : otherwise;
// biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
export const safeFormatReadableDate = <T = string>(date: any, otherwise: string | T = ''): string | T =>
    isDate(date) ? formatReadableDate(date) : otherwise;

// Safe parsing
// biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
export const safeParseClientDate = <T = undefined>(str: any, otherwise?: T): Date | T | undefined => {
    try {
        const date = parse(new Date(), 'dd.MM.yyyy', str);
        return isValid(date) ? date : otherwise;
    } catch (e) {
        return otherwise;
    }
};
// biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
export const safeParseISO = <T = undefined>(str: any, otherwise?: T): Date | T | undefined => {
    try {
        const date = parseISO(str);
        return isValid(date) ? date : otherwise;
    } catch (e) {
        return otherwise;
    }
};
// biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
export const safeToDate = <T = undefined>(value: any, otherwise?: T): Date | T | undefined => {
    try {
        const date = toDate(value);
        return isValid(date) ? date : otherwise;
    } catch (e) {
        return otherwise;
    }
};

// Accepts and returns (ISO) dates as strings
export const addDaysString = (days: number, date: string): string => formatISODate(addDays(days, parseISO(date)));
export const subDaysString = (days: number, date: string): string => formatISODate(subDays(days, parseISO(date)));

// Test helpers
export const dayOfNextMonth = (day: number): Date => addDays(day - 1, setDate(1, addMonths(1, Date.now())));
export const addDaysClient = (days: number): string => formatClientDate(addDays(days, new Date()));
export const addDaysServer = (days: number): string => formatISODate(addDays(days, new Date()));
