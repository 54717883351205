import * as objectActions from './objectActions';

export const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case objectActions.FETCH_OBJECT_RECOMMENDATION_SUCCESS: {
            return Object.assign({}, state, {
                recommendationData: {
                    items: action.data.items,
                    version: action.data.version,
                    uuid: action.data.uuid,
                },
            });
        }

        case objectActions.FETCH_OBJECT_PRICE_INFO_SUCCEEDED: {
            return Object.assign({}, state, { priceInfo: action.data });
        }

        case objectActions.FETCH_OBJECT_PRICE_INFO_FAILED: {
            if (!state.priceInfo) {
                return state;
            }
            return Object.assign({}, state, { priceInfo: null });
        }

        default: {
            return state;
        }
    }
}
