export interface BannerFilters {
    page?: number;
    destination?: {
        continent?: string;
        country?: string;
        city?: string;
    };
    // biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
    [key: string]: any;
}

export const updateBannerFilterValues = (filters: BannerFilters): void => {
    const { MessageBus } = require('@podium/browser');
    const messageBus = new MessageBus();
    // try-catch is needed because banner code gets broken by ad blockers and the pub/sub is not truly async
    try {
        messageBus.publish('search', 'filters', filters);
    } catch (_e) {
        // do nothing
    }
};

export const setInitialBannerFilterValues = (filters: BannerFilters): void => {
    const { MessageBus } = require('@podium/browser');
    const messageBus = new MessageBus();
    // try-catch is needed because banner code gets broken by ad blockers and the pub/sub is not truly async
    try {
        Object.entries(filters).length && messageBus.publish('page', 'loaded', filters);
    } catch (_e) {
        // do nothing
    }
};
