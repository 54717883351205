import * as profileActions from './profileActions';

export const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case profileActions.POPULATE_PROFILE_SUCCESS: {
            return { ...state, ...action.data };
        }
        case profileActions.POPULATE_PROFILE_FAILED: {
            return state;
        }
        default: {
            return state;
        }
    }
}
