// biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
export const queryString = (data: Record<string, any> = {}, prependQuestionMark = true): string => {
    const str = Object.keys(data)
        .filter(
            (key) =>
                typeof data[key] !== 'undefined' &&
                data[key] !== null &&
                data[key] !== '' &&
                (!Array.isArray(data[key]) || data[key].length),
        )
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
        .join('&');
    return prependQuestionMark && str.length ? `?${str}` : str;
};

export const queryParamToString = (param: string | number | readonly string[] | undefined): string | undefined => {
    if (typeof param === 'string' && param !== 'undefined') {
        return param;
    }

    if (Array.isArray(param)) {
        return String(param[0]);
    }

    if (typeof param === 'number') {
        return String(param);
    }
};

// Use this instead of method above if used for server side props on Next Pages (these do not handle undefined)...
export const queryParamToStringOrNull = (param: string | number | readonly string[] | undefined): string | null => {
    if (typeof param === 'string' && param !== 'undefined') {
        return param;
    }

    if (Array.isArray(param)) {
        return String(param[0]);
    }

    if (typeof param === 'number') {
        return String(param);
    }

    return null;
};
