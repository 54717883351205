import { configureStore } from '@reduxjs/toolkit';
import type { Store } from 'redux';
import { createWrapper, type MakeStore } from 'next-redux-wrapper';
import thunkMiddleware from 'redux-thunk';

import type { FhhState } from '@/domain';
import { isDebugMode } from '@/constants';
import { reducer } from './reducer';

// Needed to make the type of store.dispatch() handle thunk actions
import type {} from 'redux-thunk/extend-redux';

// biome-ignore lint/suspicious/noExplicitAny: Not all redux is typescript yet
const makeStore: MakeStore<any> = ({ reduxWrapperMiddleware }) =>
    configureStore({
        reducer,
        middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), reduxWrapperMiddleware, thunkMiddleware],
    });

export const wrapper = createWrapper<Store<FhhState>>(makeStore, { debug: isDebugMode });
