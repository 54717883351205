import * as actions from './priceActions';

export const initialState = {
    adId: null,
    currency: null,
    defaultPriceDay: null,
    priceDayInWeekend: null,
    discountWeek: null,
    discountMonth: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_PRICE_INFO_PENDING: {
            return { ...state, loading: true };
        }

        case actions.POPULATE_PRICE_INFO_SUCCESS: {
            return { ...state, ...action.data, loading: false };
        }

        case actions.POPULATE_PRICE_INFO_FAILED: {
            return { ...state, message: action.error, loading: false };
        }

        case actions.POST_PRICE_INFO_SUCCESS: {
            return { ...state, ...action.response, loading: false };
        }

        case actions.POST_PRICE_INFO_FAILED: {
            return { ...state, message: action.error, loading: false };
        }

        default: {
            return state;
        }
    }
};

export default reducer;
