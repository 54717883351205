export const isDebugMode = Boolean(process.env.DEBUG);
export const environment = process.env.NODE_ENV;

export const DEFAULT_CURRENCY = 'NOK';

export const ABROAD_RULES_ON_HELPCENTER =
    'https://hjelpesenter.finn.no/hc/no/articles/17892728220562-Spesifikke-regler-for-FINN-reise-feriehjem-og-hytter-i-utlandet';
export const TJVR_RULES_ON_HELPCENTER =
    'https://hjelpesenter.finn.no/hc/no/articles/202390211-Spesifikke-regler-for-FINN-reise-feriehus-og-hytter';
export const TJVR_INSURANCE_TERMS_ON_HELPCENTER = 'https://hjelpesenter.finn.no/hc/no/article_attachments/18133530094738';
