import { fetchBookingDataSsr, postBookingData } from '@/api/api';

export const POPULATE_BOOKINGS_PENDING = 'POPULATE_BOOKINGS_PENDING';
export const POPULATE_BOOKINGS_SUCCESS = 'POPULATE_BOOKINGS_SUCCESS';
export const POPULATE_BOOKINGS_FAILED = 'POPULATE_BOOKINGS_FAILED';
export const CREATE_BOOKING_SUCCESS = 'CREATE_BOOKING_SUCCESS';
export const CREATE_BOOKING_FAILED = 'CREATE_BOOKING_FAILED';
export const UPDATE_BOOKING_SUCCESS = 'UPDATE_BOOKING_SUCCESS';
export const UPDATE_BOOKING_FAILED = 'UPDATE_BOOKING_FAILED';
export const DELETE_BOOKING_SUCCESS = 'DELETE_BOOKING_SUCCESS';
export const DELETE_BOOKING_FAILED = 'DELETE_BOOKING_FAILED';
export const TOGGLE_SELECTED_BOOKING = 'TOGGLE_SELECTED_BOOKING';
export const TOGGLE_NEW_BOOKING = 'TOGGLE_NEW_BOOKING';
export const TOGGLE_BOOKING_STATUS = 'TOGGLE_BOOKING_STATUS';

const zeroPad = (num, places) => String(num).padStart(places, '0');

const withTimeAs = (time, str) => (str ? `${str.substring(0, str.length - 9)}T${zeroPad(time, 2)}:00` : str);

const populateBookingsPending = () => ({
    type: POPULATE_BOOKINGS_PENDING,
});

const populateBookingsSuccess = (data) => ({
    type: POPULATE_BOOKINGS_SUCCESS,
    data,
});

const populateBookingsFailed = (error) => ({
    type: POPULATE_BOOKINGS_FAILED,
    error,
});

const createBookingSuccess = (response, data) => ({
    type: CREATE_BOOKING_SUCCESS,
    response,
    data: {
        ...data,
        start: withTimeAs(15, data.start),
        end: withTimeAs(12, data.end),
    },
});

const createBookingFailed = (error) => ({
    type: CREATE_BOOKING_FAILED,
    error,
});

const updateBookingSuccess = (data) => ({
    type: UPDATE_BOOKING_SUCCESS,
    data,
});

const updateBookingFailed = (error) => ({
    type: UPDATE_BOOKING_FAILED,
    error,
});

const deleteBookingSuccess = (data) => ({
    type: DELETE_BOOKING_SUCCESS,
    data,
});

const deleteBookingFailed = (error) => ({
    type: DELETE_BOOKING_FAILED,
    error,
});

export const toggleSelectedBooking = (data) => ({
    type: TOGGLE_SELECTED_BOOKING,
    data,
});

export const toggleBookingStatus = (data) => ({
    type: TOGGLE_BOOKING_STATUS,
    data,
});

export const toggleNewBooking = (data) => ({
    type: TOGGLE_NEW_BOOKING,
    data,
});

export const populateBookingsSsr = (adId, authCookies) => (dispatch) => {
    dispatch(populateBookingsPending());
    return fetchBookingDataSsr(adId, authCookies)
        .then((data) => dispatch(populateBookingsSuccess(data)))
        .catch((error) => dispatch(populateBookingsFailed(error)));
};

export const createBooking = (data) => (dispatch) => {
    dispatch(populateBookingsPending());

    return postBookingData('create', data)
        .then((response) => {
            dispatch(createBookingSuccess(response, data));
        })
        .catch((error) => {
            dispatch(createBookingFailed(error));
        });
};

export const updateBooking = (data, revert) => (dispatch) => {
    dispatch(populateBookingsPending());

    return postBookingData('update', data)
        .then((response) => dispatch(updateBookingSuccess(typeof response === 'object' ? response : data)))
        .catch((error) => {
            dispatch(updateBookingFailed(error));
            if (revert) revert();
        });
};

export const deleteBooking = (data) => (dispatch) => {
    dispatch(populateBookingsPending());

    return postBookingData('delete', data)
        .then(() => dispatch(deleteBookingSuccess(data)))
        .catch((error) => dispatch(deleteBookingFailed(error)));
};
