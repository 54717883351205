import { fetchPriceInfoSsr } from '@/api/api';

export const POPULATE_PRICE_INFO_FAILED = 'POPULATE_PRICE_INFO_FAILED';
export const POPULATE_PRICE_INFO_SUCCESS = 'POPULATE_PRICE_INFO_SUCCESS';
export const SET_PRICE_INFO_PENDING = 'SET_PRICE_INFO_PENDING';
export const POST_PRICE_INFO_SUCCESS = 'POST_PRICE_INFO_SUCCESS';
export const POST_PRICE_INFO_FAILED = 'POST_PRICE_INFO_FAILED';

const setPriceInfoPending = () => ({
    type: SET_PRICE_INFO_PENDING,
});

const populatePriceInfoFailed = (error) => ({
    type: POPULATE_PRICE_INFO_FAILED,
    error: typeof error === 'string' ? error : JSON.stringify(error),
});

const populatePriceInfoSuccess = (data) => ({
    type: POPULATE_PRICE_INFO_SUCCESS,
    data,
});

export const populatePriceInfoSsr = (adId, authCookies) => (dispatch) => {
    dispatch(setPriceInfoPending());

    return fetchPriceInfoSsr(adId, authCookies)
        .then((response) => dispatch(populatePriceInfoSuccess(response)))
        .catch((error) => dispatch(populatePriceInfoFailed(error)));
};
