import { formatISODateTime, parseISO } from 'shared/util/datetime-fns';
import * as actions from './bookingActions';
import type { Booking, BookingData } from '@/domain';

export const initialState: BookingData = {
    adId: null,
    enabled: false,
    rentalEnabled: false,
    bookings: [],
    outdated: [],
    selected: null,
    bookingStatus: 'NOT_STARTED',
    newEmptyBooking: false,
};

const formatDate = (date: string | undefined) => {
    if (!date) return '';

    const newDate = parseISO(date);
    newDate.setHours(15);
    return `${formatISODateTime(newDate)}:00`; // include seconds
};
const createBooking = (entry: Booking): Booking => ({
    bookingId: entry.bookingId,
    ownerFinnUserId: entry.ownerFinnUserId,
    renterFinnUserId: entry.renterFinnUserId,
    rentalId: entry.rentalId,
    startDate: formatDate(entry.startDate),
    endDate: formatDate(entry.endDate),
    bookingReference: entry.bookingReference,
    bookingNotes: entry.bookingNotes,
    phoneNumber: entry.phoneNumber,
    email: entry.email,
    name: entry.name,
    image: entry.image ?? null,
});

function updateIfEntryExists(array: Booking[], data: Booking) {
    return array.map((entry) => {
        if (entry.bookingId === data.bookingId) {
            return { ...data };
        }
        return entry;
    });
}

function sortByStartDate(bookings) {
    return bookings.sort((a, b) => (a.startDate > b.startDate ? 1 : -1));
}

export default function reducer(state: BookingData = initialState, action) {
    switch (action.type) {
        case actions.POPULATE_BOOKINGS_PENDING: {
            return { ...state };
        }
        case actions.POPULATE_BOOKINGS_SUCCESS: {
            const bookings = action.data.bookings.map(createBooking);

            return { ...state, ...action.data, bookings };
        }
        case actions.CREATE_BOOKING_SUCCESS: {
            const newBooking = createBooking({
                bookingId: Number(action.response.bookingId),
                ownerFinnUserId: action.response.ownerFinnUserId,
                renterFinnUserId: action.response.renterFinnUserId,
                rentalId: action.response.rentalId,
                startDate: action.response.startDate,
                endDate: action.response.endDate,
                name: action.response.name,
                phoneNumber: action.response.phone,
                email: action.response.email,
                bookingReference: null,
                bookingNotes: action.response.bookingNotes,
                image: action.response.image ?? null,
            });
            const bookings = [...state.bookings, newBooking];

            return { ...state, bookings: sortByStartDate(bookings), newEmptyBooking: false, selected: newBooking, bookingStatus: 'OK' };
        }
        case actions.UPDATE_BOOKING_SUCCESS: {
            const updatedBookings = updateIfEntryExists(state.bookings, createBooking({ ...action.data }));

            return {
                ...state,
                bookings: updatedBookings,
                outdated: updateIfEntryExists(state.outdated, action.data), // TODO REIS-5376 remove outdated
                newEmptyBooking: false,
                selected: updatedBookings.find((entry) => entry.bookingId === action.data.bookingId) ?? null,
                bookingStatus: 'OK',
            };
        }
        case actions.DELETE_BOOKING_SUCCESS: {
            return {
                ...state,
                bookings: state.bookings.filter((entry) => Number(entry.bookingId) !== action.data.bookingId),
                outdated: state.outdated.filter((entry) => Number(entry.bookingId) !== action.data.bookingId),
                newEmptyBooking: false,
                selected: null,
                bookingStatus: 'OK',
            };
        }

        case actions.TOGGLE_SELECTED_BOOKING: {
            return { ...state, newEmptyBooking: false, selected: action.data ?? null };
        }

        case actions.TOGGLE_BOOKING_STATUS: {
            return { ...state, bookingStatus: action.data ?? 'NOT_STARTED' };
        }

        case actions.TOGGLE_NEW_BOOKING: {
            return { ...state, newEmptyBooking: action.data.newEmptyBooking ?? false, selected: action.data.selected ?? null };
        }

        case actions.POPULATE_BOOKINGS_FAILED: {
            return { ...state };
        }

        case actions.CREATE_BOOKING_FAILED:
        case actions.DELETE_BOOKING_FAILED:
        case actions.UPDATE_BOOKING_FAILED: {
            return { ...state, bookingStatus: 'ERROR' };
        }

        default: {
            return state;
        }
    }
}
