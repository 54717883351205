import { fetchCalendarPriceSsr, fetchPriceForImport, postOrderData } from '@/api/api';

export const SET_ORDER_PENDING = 'SET_ORDER_PENDING';
export const POPULATE_ORDER_SUCCESS = 'POPULATE_ORDER_SUCCESS';
export const POPULATE_ORDER_FAILED = 'POPULATE_ORDER_FAILED';
export const POST_ORDER_SUCCESS = 'POST_ORDER_SUCCESS';
export const POST_ORDER_FAILED = 'POST_ORDER_FAILED';
export const SET_STEP = 'SET_STEP';
export const SET_PETS = 'SET_PETS';
export const SET_SELECTED_SERVICE = 'SET_SELECTED_SERVICE';

const setOrderPending = () => ({
    type: SET_ORDER_PENDING,
});

const populateOrderSuccess = (data, response) => ({
    type: POPULATE_ORDER_SUCCESS,
    data,
    response,
});

const populateOrderFailed = (error) => ({
    type: POPULATE_ORDER_FAILED,
    error,
});

const postOrderSuccess = (response) => ({
    type: POST_ORDER_SUCCESS,
    response,
});

const postOrderFailed = (error) => ({
    type: POST_ORDER_FAILED,
    error,
});

export const populateOrder = (data) => (dispatch, getState) => {
    dispatch(setOrderPending());
    const state = { ...getState().orderData, ...data };

    return fetchPriceForImport({
        adId: state.adId,
        fromDate: state.fromDate,
        toDate: state.toDate,
        adults: state.adults,
        children: state.children,
        infants: state.infants,
    })
        .then((res) => dispatch(populateOrderSuccess(data, res)))
        .catch((error) => dispatch(populateOrderFailed(error)));
};

export const populateOrderSsr = (data) => (dispatch) => {
    dispatch(setOrderPending());

    return fetchCalendarPriceSsr(data)
        .then((res) => dispatch(populateOrderSuccess(data, res)))
        .catch((error) => dispatch(populateOrderFailed(error)));
};

export const setAdults = (value) => (dispatch) => dispatch(populateOrder({ adults: value }));

export const setChildren = (value) => (dispatch) => dispatch(populateOrder({ children: value }));

export const setInfants = (value) => (dispatch) => dispatch(populateOrder({ infants: value }));

export const setPets = (value) => (dispatch) => dispatch({ type: SET_PETS, value });

export const setService = (id, value) => (dispatch) => dispatch({ type: SET_SELECTED_SERVICE, id, value });

export const setStep = (value) => (dispatch) => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    return dispatch({ type: SET_STEP, value });
};

export const postOrder = (data) => (dispatch) => {
    dispatch(setOrderPending());

    return postOrderData(data)
        .then((res) => {
            if (!res.bookingNumber) {
                return Promise.reject(res.message);
            }
            dispatch(postOrderSuccess(res));
            dispatch(setStep(3));
        })
        .catch((error) => dispatch(postOrderFailed(error)));
};
