import { fetchProfileSsr } from '@/api/api';

export const POPULATE_PROFILE_SUCCESS = 'POPULATE_PROFILE_SUCCESS';
export const POPULATE_PROFILE_FAILED = 'POPULATE_PROFILE_FAILED';

const populateProfileSuccess = (data) => ({
    type: POPULATE_PROFILE_SUCCESS,
    data,
});

const populateProfileFailed = (error) => ({
    type: POPULATE_PROFILE_FAILED,
    error,
});

export const populateProfile = (loginId) => (dispatch) =>
    fetchProfileSsr(loginId)
        .then((data) => dispatch(populateProfileSuccess(data)))
        .catch((error) => dispatch(populateProfileFailed(error)));
