import * as orderActions from './orderActions';

const initialOrderData = {
    step: 1,
    adults: 2,
    children: 0,
    infants: 0,
    pets: 0,
    fromDate: null,
    toDate: null,
    loading: false,
    selectedServices: {},
};
const initialOrderResponse = {
    available: false,
    message: null,
    bookingPrice: null,
    totalPrice: null,
    currency: 'NOK',
    currencySymbol: 'kr',
    brand: '',
    mandatoryServices: [],
    optionalServices: [],
};

export const initialState = {
    ...initialOrderData,
    ...initialOrderResponse,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case orderActions.SET_ORDER_PENDING: {
            return { ...state, loading: true };
        }

        case orderActions.POPULATE_ORDER_SUCCESS: {
            return { ...state, ...action.data, ...action.response, loading: false };
        }
        case orderActions.POPULATE_ORDER_FAILED: {
            return { ...state, message: action.error, loading: false };
        }

        case orderActions.POST_ORDER_SUCCESS: {
            return { ...state, ...action.response, loading: false };
        }
        case orderActions.POST_ORDER_FAILED: {
            return { ...state, message: action.error, loading: false };
        }

        case orderActions.SET_STEP: {
            return { ...state, step: action.value };
        }

        case orderActions.SET_PETS: {
            return { ...state, pets: action.value };
        }

        case orderActions.SET_SELECTED_SERVICE: {
            const selectedServices = { ...state.selectedServices };
            if (action.value === 0) {
                delete selectedServices[action.id];
            } else {
                selectedServices[action.id] = action.value;
            }
            return { ...state, selectedServices };
        }

        default: {
            return state;
        }
    }
}
