import Script from 'next/script';
import type { AppProps } from 'next/app';

import { wrapper } from '@/store/store';

import '@/styles/common.css';
import '@/styles/favoriteHeartComponent.css';
import '@/styles/feedback.css';
import '@/styles/profile.css';
import '@/styles/map.css';

import { UnleashProvider } from '@/hooks/unleash';
import { Provider } from 'react-redux';

declare global {
    interface Window {
        TrackJS?: {
            install: (data: { token: string; application: string; version: string; enabled: boolean }) => void;
        };
    }
}

const FhhApp = ({ Component, pageProps }: AppProps) => {
    const store = wrapper.useStore();
    return (
        <>
            <Provider store={store}>
                <UnleashProvider toggles={pageProps?.unleash}>
                    <Component {...pageProps} />
                </UnleashProvider>
            </Provider>
            <Script
                src="https://cdn.trackjs.com/agent/v3/latest/t.js"
                onLoad={() => {
                    window.TrackJS?.install?.({
                        token: '74bb3e28a1604fd2b28c72c568722d21',
                        application: 'finn-travel-fhh',
                        version: `'${process.env.NEXT_PUBLIC_GIT_COMMIT_SHA}'`,
                        enabled: location.host.indexOf('local') !== 0 && location.host.indexOf('127.0.0') !== 0,
                    });
                }}
                crossOrigin=""
            />
        </>
    );
};
export default FhhApp;
