import { createContext, type PropsWithChildren, useContext } from 'react';

export const createUnleash = <T,>(defaultToggles: T | undefined = undefined) => {
    interface Props {
        toggles: T;
    }

    const UnleashContext = createContext<T | undefined>(defaultToggles);

    const UnleashProvider = ({ toggles, children }: PropsWithChildren<Props>) => (
        <UnleashContext.Provider value={{ ...toggles }}>{children}</UnleashContext.Provider>
    );

    const useUnleash = (): T | undefined => useContext(UnleashContext);

    return {
        UnleashProvider,
        useUnleash,
    };
};
